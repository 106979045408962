import {
  testMediaConnectionBitrate,
  AudioInputTest,
  testAudioInputDevice,
  MediaConnectionBitrateTest,
  AudioOutputTest,
  testAudioOutputDevice,
  testVideoInputDevice,
  VideoInputTest,
} from '@twilio/rtc-diagnostics';
import { Device, Connection, PreflightTest } from 'twilio-client';
import RTCSample from 'twilio-client/es5/twilio/rtc/sample';
import { TestResultEnum, TestResult, TestNameEnum } from './types';

export function getJSON(url: string) {
  return fetch(url).then(async (res) => {
    if (res.status === 401) {
      throw new Error('expired');
    }

    if (!res.ok) {
      throw new Error(res.statusText);
    }

    return await res.json();
  });
}

function getVoiceToken() {
  return getJSON('app/token').then((res) => res.token as string);
}
function getTURNCredentials() {
  return getJSON('app/turn-credentials').then((res) => res.iceServers);
}
const reports: any[] = [];

export const preflightTestRunner = async (resolve: (status: TestResultEnum, name: TestNameEnum) => void) => {
  try {
    const token = await getVoiceToken();
    const iceServers = await getTURNCredentials();

    const preflightOptions: PreflightTest.Options = {
      debug: false,
      signalingTimeoutMs: 10000,
      codecPreferences: [Connection.Codec.PCMU, Connection.Codec.Opus],
      fakeMicInput: true,
    };
    const preflightTest = Device.runPreflight(token, {
      ...preflightOptions,
      iceServers,
    });
    let hasConnected = false;
    let latestSample: RTCSample;

    preflightTest.on(PreflightTest.Events.Completed, (report: PreflightTest.Report) => {
      resolve(TestResultEnum.SUCCESS, TestNameEnum.VOICE_BANDWIDTH);
      resolve(TestResultEnum.SUCCESS, TestNameEnum.VOICE_OPUS);
      resolve(TestResultEnum.SUCCESS, TestNameEnum.VOICE_PCMU);
      reports.push(report);
    });

    preflightTest.on(PreflightTest.Events.Connected, () => {
      hasConnected = true;
    });

    preflightTest.on(PreflightTest.Events.Failed, (error) => {
      resolve(TestResultEnum.ERROR, TestNameEnum.VOICE_BANDWIDTH);
      reports.push(error);
      error.hasConnected = hasConnected;
      error.latestSample = latestSample;
    });

    preflightTest.on(PreflightTest.Events.Warning, (warningName, warningData) => {});
  } catch (e) {
    resolve(TestResultEnum.ERROR, TestNameEnum.TWILIO_TOKEN);
  }
};

export const bitrateTestRunner = async (resolve: (status: TestResultEnum, name: TestNameEnum) => void) => {
  try {
    const iceServers = await getTURNCredentials();
    const bitrateTest = testMediaConnectionBitrate({ iceServers });

    bitrateTest.on(MediaConnectionBitrateTest.Events.Error, (report) => {
      reports.push(report);
      resolve(TestResultEnum.ERROR, TestNameEnum.UDP_CONNECTIVITY);
    });

    bitrateTest.on(MediaConnectionBitrateTest.Events.End, (error) => {
      reports.push(error);
      resolve(TestResultEnum.SUCCESS, TestNameEnum.UDP_CONNECTIVITY);
      resolve(TestResultEnum.SUCCESS, TestNameEnum.TCP_CONNECTIVITY);
      resolve(TestResultEnum.SUCCESS, TestNameEnum.TLS_CONNECTIVITY);
    });

    setTimeout(() => {
      bitrateTest.stop();
    }, 15000);
  } catch (e) {
    resolve(TestResultEnum.ERROR, TestNameEnum.TWILIO_TOKEN);
  }
};

export const audioInputTestRunner = async (resolve: (status: TestResultEnum, name: TestNameEnum) => void) => {
  const audioInputDeviceTest = testAudioInputDevice();

  audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
    reports.push(error);
    resolve(TestResultEnum.ERROR, TestNameEnum.AUDIO_TURN);
  });

  audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
    reports.push(report);
    resolve(TestResultEnum.SUCCESS, TestNameEnum.AUDIO_TURN);
  });

  setTimeout(() => {
    audioInputDeviceTest.stop();
  }, 10000);
};

export const videoInputTestRunner = async (resolve: (status: TestResultEnum, name: TestNameEnum) => void) => {
  const videoInputDeviceTest = testVideoInputDevice();

  videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
    reports.push(error);
    resolve(TestResultEnum.ERROR, TestNameEnum.VIDEO_CONNECTIVITY);
  });

  videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
    reports.push(report);
    resolve(TestResultEnum.SUCCESS, TestNameEnum.VIDEO_CONNECTIVITY);
  });

  setTimeout(() => {
    videoInputDeviceTest.stop();
  }, 10000);
};

const prepareTests = async (resolve: Function, test: Function) => {
  const resolver = (status: any, name: TestNameEnum) => {
    resolve({
      name,
      status: status === TestResultEnum.ERROR ? TestResultEnum.ERROR : TestResultEnum.SUCCESS,
      reports,
    });
  };
  const res = await test(resolver);
};

export const startTwilioDiagnostic = (): Promise<TestResult>[] => {
  return [preflightTestRunner, bitrateTestRunner, audioInputTestRunner, videoInputTestRunner].map(
    (test) =>
      new Promise<TestResult>((resolve) => {
        prepareTests(resolve, test);
      }),
  );
};

export * from './types';
