export enum BrowserNameEnum {
  CHROME = 'Chrome',
  EDGE = 'Edge',
}

export enum TestResultEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum TestErrorEnum {
  INCOMPLETE = 'Incomplete',
  UNSUPPORTED = 'Unsupported',
  OLD = 'Old',
  EMPTY = '',
}
