import styled, { css } from 'styled-components';

export const Input = styled.input`
  width: 100%;
  max-width: 300px;
  height: 100%;
  color: #373636;
  font-size: 16px;
  padding: 15px 34px 15px 24px;
  border: 1px solid #00aced;
  border-radius: 5px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #949494;
    `}
`;
