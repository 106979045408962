import { TestResultEnum, TestResult, ManifestItem } from './types';

const MAIN_MANIFEST_URL =
  'https://living-security-res.cloudinary.com/video/upload/sp_full_hd/v1/production/Nano-Modules/Clean/IoT_4mbps_CLEAN_gpcnzn.m3u8?_s=vp-1.5.3';
const NEW_LINE_REG_EXP = '\n';
const TS_FILE_REG_EXP = /.*\.ts/;
const RESOLUTION_REG_EXP = 'RESOLUTION=';
const VIDEO_DOMAIN = 'https://living-security-res.cloudinary.com';
const PLAYLIST_EXTENSION = '.m3u8';
const TS_EXTENSION = '.ts';
const SUCCESS_STATUS = 200;

async function fetchWithTimeout(resource: string, timeout = 20000) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

const parseHLSURLS = (raw: string) => {
  const lines = raw.split(NEW_LINE_REG_EXP);

  lines.pop();
  lines.shift();

  const formatted = [];

  for (let i = 0; i < lines.length - 1; i += 2) {
    const details = lines[i];
    const resolution = details.split(RESOLUTION_REG_EXP)[1];
    const url = `${VIDEO_DOMAIN}${lines[i + 1]}`;

    formatted.push({ details, url, resolution });
  }

  return formatted;
};

const loadManifest = async (resolve: Function, { url, resolution }: ManifestItem) => {
  let status: TestResultEnum = TestResultEnum.SUCCESS;

  try {
    const response = await fetchWithTimeout(url);

    if (response.status !== SUCCESS_STATUS) {
      throw new Error();
    }

    const manifestBody = await response.text();
    const found = manifestBody.match(TS_FILE_REG_EXP);

    if (found === null) {
      status = TestResultEnum.ERROR;
    } else {
      const tsUrl = url.replace(PLAYLIST_EXTENSION, TS_EXTENSION);
      const tsUrlRespose = await fetchWithTimeout(tsUrl);

      if (tsUrlRespose.status !== SUCCESS_STATUS) {
        throw new Error();
      }
    }
  } catch {
    status = TestResultEnum.ERROR;
  } finally {
    resolve({
      resolution,
      status,
    });
  }
};

export const startCloudinaryDiagnostic = async () => {
  const response = await fetchWithTimeout(MAIN_MANIFEST_URL);

  if (response.status !== SUCCESS_STATUS) {
    throw new Error();
  }

  const body = await response.text();
  const parsedURLS = parseHLSURLS(body);

  if (!parsedURLS.length) {
    throw new Error();
  }

  return parsedURLS.map(
    (parsedURL) =>
      new Promise<TestResult>((resolve) => {
        loadManifest(resolve, parsedURL);
      }),
  );
};

export * from './types';
