import React, { useRef } from 'react';

const confetti = require('canvas-confetti');

const Confetti = () => {
  const canvasRef = useRef(null);

  const myConfetti = confetti.create(canvasRef.current, {
    resize: true,
    useWorker: true,
  });
  myConfetti({
    particleCount: 100,
    spread: 160,
    origin: { y: 1 },
  });

  return <canvas ref={canvasRef} />;
};

export default Confetti;
