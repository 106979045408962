export enum TestResultEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type TestResult = {
  resolution: string;
  status: keyof typeof TestResultEnum;
};

export interface ManifestItem {
  resolution: string;
  details: string;
  url: string;
}
