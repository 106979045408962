import { useContext } from 'react';

import systemCheckContext from '../contexts/systemCheckContext';

export default function useSystemCheckContext() {
  const context = useContext(systemCheckContext);

  if (!context) {
    throw new Error('useSystemCheckContext must be used within a SystemCheckProvider');
  }

  return context;
}
