import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';

function NoInternetModal({ open }: { open: boolean }) {
  const { t } = useTranslation();

  const refreshPage = () => {
    window.location.reload();
  };

  if (!open) return null;
  return (
    <Modal>
      <Styled.ContentWrapper>
        <Styled.Text>{t('common:internet-down')}</Styled.Text>
        <Button onClick={refreshPage}>{t('common:refresh')}</Button>
      </Styled.ContentWrapper>
    </Modal>
  );
}

export default NoInternetModal;
