export default (node) => {
  const container = document.createElement('div');

  container.innerHTML = node.outerHTML;

  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';
  container.style.opacity = 0;

  const activeSheets = Array.prototype.slice.call(document.styleSheets).filter((sheet) => !sheet.disabled);

  document.body.appendChild(container);

  window.getSelection().removeAllRanges();

  const range = document.createRange();

  range.selectNode(container);
  window.getSelection().addRange(range);

  document.execCommand('copy');

  for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;

  document.execCommand('copy');

  for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;

  document.body.removeChild(container);
};
