import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import { datadogLogs } from '@datadog/browser-logs';
import i18n from './utils/i18n';

import './assets/styles/fonts.scss';
import './assets/styles/base.scss';

import TestingPage from './containers/TestingPage';

if (process.env.REACT_APP_DATADOG_CLIENT_ID) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID,
    site: 'datadoghq.com',
    service: 'compatibility-test',
    env: process.env.REACT_APP_DATADOG_ENV || 'dev',
    sampleRate: 100,
    silentMultipleInit: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/" component={TestingPage} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </SnackbarProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
