import styled, { css } from 'styled-components';

export const CopyToClipboard = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  color: #2f80ed;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
`;

export const Img = styled.img`
  margin-right: 8px;
  height: 100%;
`;

export const Text = styled.div``;

export const SuccessMessage = styled.div`
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #5be5be;
  background-color: #f4f7f9;
  ${({ isClicked }) =>
    isClicked &&
    css`
      opacity: 1;
      cursor: default;
    `}
`;
