export enum ContentTypeEnum {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export enum TestStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type NetworkSpeedTestProps = {
  type: keyof typeof ContentTypeEnum;
  mediaLink: string;
  sizeInBytes: number;
  uploadUrl: string;
};

export type TestCase = {
  startTime: number;
  endTime: number;
};

export type Result = {
  status: keyof typeof TestStatusEnum;
  speedInBps: number;
  speedInKbps: number;
  speedInMbps: number;
};

export type Results = {
  download: Result;
  // upload: Result;
};
