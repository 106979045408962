export const REGIONS = {
  US: 'US',
  Europe: 'Europe',
  Asia: 'Asia',
};

export type RegionKeys = keyof typeof REGIONS;
export type Region = typeof REGIONS[RegionKeys];

export default (region: Region = REGIONS.US) => ({
  apiKey: JSON.parse(process.env.REACT_APP_FIREBASE_API_KEY || '{}')[region] || '',
  authDomain: JSON.parse(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '{}')[region] || '',
  databaseURL: JSON.parse(process.env.REACT_APP_FIREBASE_DB_URL || '{}')[region] || '',
  projectId: JSON.parse(process.env.REACT_APP_FIREBASE_PROJECT_ID || '{}')[region] || '',
  storageBucket: JSON.parse(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '{}')[region] || '',
  messagingSenderId: JSON.parse(process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '{}')[region] || '',
  appId: JSON.parse(process.env.REACT_APP_FIREBASE_APP_ID || '{}')[region] || '',
  measurementId: JSON.parse(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '{}')[region] || '',
});
