import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { ContentTypeEnum, NetworkSpeedTestProps, TestStatusEnum, TestCase, Results } from './types';

function generateTestData(sizeInBytes: number): string {
  return '**********'.repeat(sizeInBytes / 10);
}

export const DEFAULT_RESULTS = {
  download: {
    status: TestStatusEnum.IN_PROGRESS,
    speedInBps: 0,
    speedInKbps: 0,
    speedInMbps: 0,
  },
  /*
  upload: {
    status: TestStatusEnum.IN_PROGRESS,
    speedInBps: 0,
    speedInKbps: 0,
    speedInMbps: 0,
  },
   */
};

const DOWNLOAD_TIMEOUT = 30000;
const UPLOAD_TIMEOUT = 60000;

export function useNetworkSpeedTest({
  type = ContentTypeEnum.VIDEO,
  mediaLink = 'https://platform-cdn.livingsecurity.com/videos/CEO-IntroVideo_kbgmjx.mp4',
  sizeInBytes = 22800000,
  uploadUrl,
}: NetworkSpeedTestProps) {
  const [results, setResults] = useState<Results>(DEFAULT_RESULTS);

  const calculateSpeed = useCallback((testCases: number[], sizeInBytes: number) => {
    const loadedBits = sizeInBytes * 8;
    const averageDuration = testCases.reduce((acc, time) => acc + time, 0) / testCases.length / 1000;
    const speedInBps = loadedBits / averageDuration;
    const speedInKbps = speedInBps / 1000;
    const speedInMbps = speedInKbps / 1000;

    return {
      speedInBps,
      speedInKbps,
      speedInMbps,
    };
  }, []);

  const startDownloadTest = useCallback(async () => {
    const runTest = (): Promise<TestCase> => {
      return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();
        const currentMediaLink = `${mediaLink}?date=${new Date()}`;
        let timeout = false;
        if (type === ContentTypeEnum.VIDEO) {
          const video = document.createElement('video');
          video.src = currentMediaLink;

          video.oncanplaythrough = () => {
            if (!timeout) {
              resolve({ startTime, endTime: new Date().getTime() });
            }
          };
        } else {
          const downloadImgSrc = new Image();

          downloadImgSrc.onload = () => {
            if (!timeout) {
              resolve({ startTime, endTime: new Date().getTime() });
            }
          };
          downloadImgSrc.src = currentMediaLink;
        }
        setTimeout(() => {
          timeout = true;
          reject(new Error('Download timeout'));
        }, DOWNLOAD_TIMEOUT);
      });
    };

    const sendTimes = 6;
    const testCases = [];
    let errorCaught = false;
    for (let i = 1; i <= sendTimes && !errorCaught; i++) {
      try {
        const { startTime, endTime } = await runTest();
        testCases.push(endTime - startTime);
      } catch (e) {
        errorCaught = true;
      }
    }
    if (!errorCaught) {
      const { speedInBps, speedInKbps, speedInMbps } = calculateSpeed(testCases, sizeInBytes);
      setResults((prev) => ({
        ...prev,
        download: {
          status: TestStatusEnum.SUCCESS,
          speedInBps,
          speedInKbps,
          speedInMbps,
        },
      }));
    } else {
      setResults((prev) => ({
        ...prev,
        download: {
          status: TestStatusEnum.FAILED,
          speedInBps: 0,
          speedInKbps: 0,
          speedInMbps: 0,
        },
      }));
    }
  }, [mediaLink, type, sizeInBytes]);

  const startUploadTest = useCallback(async () => {
    const sendTimes = 6;
    const testCases = [];
    const size = 6000000;
    let errorCaught = false;

    setTimeout(() => {
      try {
        throw new Error('Upload timeout');
      } catch (e) {
        errorCaught = true;
      }
    }, UPLOAD_TIMEOUT);

    for (let i = 1; i <= sendTimes && !errorCaught; i++) {
      try {
        const startTime = new Date().getTime();
        await axios.post(uploadUrl, {
          data: generateTestData(size),
        });
        const endTime = new Date().getTime();
        testCases.push(endTime - startTime);
      } catch (e) {
        errorCaught = true;
      }
    }

    if (!errorCaught) {
      const { speedInBps, speedInKbps, speedInMbps } = calculateSpeed(testCases, size);
      setResults((prev) => ({
        ...prev,
        upload: {
          status: TestStatusEnum.SUCCESS,
          speedInBps,
          speedInKbps,
          speedInMbps,
        },
      }));
    } else {
      setResults((prev) => ({
        ...prev,
        upload: {
          status: TestStatusEnum.FAILED,
          speedInBps: 0,
          speedInKbps: 0,
          speedInMbps: 0,
        },
      }));
    }
  }, [mediaLink, type, sizeInBytes]);

  useEffect(() => {
    (async () => {
      await startDownloadTest();
      // await startUploadTest();
    })();
  }, [startDownloadTest, startUploadTest]);

  return results;
}
