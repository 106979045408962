import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import copyToClipboard from 'utils/copyToClipboard';

import * as Styled from './styles';
import Success from 'assets/images/success.svg';

type CopyToClipboardProps = {
  node?: any;
};

export default ({ node }: CopyToClipboardProps) => {
  const [isClicked, setClicked] = useState(false);
  const { t } = useTranslation();

  const copy = () => {
    if (isClicked) return;

    copyToClipboard(node);
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 2000);
  };

  return (
    <Styled.CopyToClipboard onClick={copy}>
      <Styled.SuccessMessage isClicked={isClicked}>
        <Styled.Img src={Success} alt="success icon" />
        {t('common:copied-to-clipboard')}
      </Styled.SuccessMessage>
      <Styled.Text>{t('common:copy-message')}</Styled.Text>
    </Styled.CopyToClipboard>
  );
};
