import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import { sendTestResults } from 'services/test-results';
import { CheckNameEnum, CheckStatusEnum, ErrorType } from './hooks/types';
import useSystemCheck from './hooks/useSystemCheck';

import SystemCheckContext from './contexts/systemCheckContext';

import TwilioCheck from './components/TwilioCheck/TwilioCheck';
import FirebaseCheck from './components/FirebaseCheck/FirebaseCheck';
import BrowserCheck from './components/BrowserCheck/BrowserCheck';
import CloudinaryCheck from './components/CloudinaryCheck/CloudinaryCheck';
import InternetSpeedCheck from './components/InternetSpeedCheck/InternetSpeedCheck';
import Confetti from 'components/Confetti';
import Title from 'components/Title';
import Link from 'components/Link';
import Button from 'components/Button';
import Input from 'components/Input';
import Text from 'components/Text';
import CopyToClipboard from 'components/CopyToClipboard';

import isEmail from 'utils/isEmail';
import { SUPPORT_LINKS } from '../../constants';

import * as Styled from './styles';
import useOnlineStatus from '../../hooks/useOnlineStatus/useOnlineStatus';
import NoInternetModal from '../../components/Modals/NoInternetModal';
import { Region, REGIONS } from '../../utils/firebase';

export const useStyles = makeStyles({
  pageContainer: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#e8f1f3',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 10,
  },
  title: {
    fontSize: 36,
    fontWeight: 900,
    marginBottom: 20,
  },
  testingStatus: {
    marginBottom: 30,
    fontSize: 24,
    lineHeight: 1,
    color: 'rgba(0, 0, 0, 0.65)',
    cursor: 'default',
  },
  blocksWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  block: {
    margin: '0 21px',
    backgroundColor: '#ffffff',
    border: '1px solid #dfeef2',
    boxShadow: '0px 0px 10px rgba(1, 36, 71, 0.1)',
    borderRadius: 10,
  },
  testingBlock: {
    width: '521px',
    paddingBottom: 55,
  },
  regionWrapper: {
    display: 'flex',
    width: '80%',
    minWidth: '750px',
    justifyContent: 'space-between',
    marginTop: '55px',
  },
  regionBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    height: '200px',
    fontSize: 24,
    background: '#FFF',
    borderRadius: '8px',
    transition: 'background .1s',

    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(78, 179, 212, 1)',
      color: '#FFF',
    },
  },
  blockTitle: {
    fontSize: 18,
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 30,
    textAlign: 'center',
  },
});

function SystemRequirementsCheckPage() {
  const [userEmail, setUserEmail] = useState('');
  const [region, setRegion] = useState<Region | undefined>();
  const classes = useStyles();
  const { t } = useTranslation();
  const [wasOffline, setWasOffline] = useState(false);
  const isOnline = useOnlineStatus();
  const regionSelect = !region && process.env.REACT_APP_MULTI_REGION === 'true';

  const TESTING_STATUSES = useMemo(
    () => ({
      IN_PROGRESS: t('requirements-check:testing-statuses:in-progress'),
      SUCCESS: t('requirements-check:testing-statuses:success'),
      ERROR: t('requirements-check:testing-statuses:error'),
    }),
    [],
  );
  const [testingStatus, setTestingStatus] = useState(TESTING_STATUSES.IN_PROGRESS);
  const [isHelpBlockVisible, setHelpBlockVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const SYSTEM_CHECK_CONTEXT = useSystemCheck();

  const { checkEnded, checks, resultData } = SYSTEM_CHECK_CONTEXT;
  const [emailNode, setEmailNode] = useState(null);
  const [independentTests, setIndependentTests] = useState<CheckNameEnum[]>([
    CheckNameEnum.DATABASE,
    CheckNameEnum.INTERNET_SPEED,
  ]);

  const onRefChange = useCallback((node) => {
    setEmailNode(node);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const inputHandler = ({ target: { value } }: { target: { value: string } }) => {
    setUserEmail(value);
  };

  const sendResults = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await sendTestResults(userEmail, resultData);
      enqueueSnackbar(response.data.message);
    } catch (e) {
      enqueueSnackbar(t('common:report-error'));
    } finally {
      setIsLoading(false);
      setUserEmail('');
    }
  };

  useEffect(() => {
    if (checkEnded) {
      const newStatus = Object.values(checks).every(({ status }) => status === CheckStatusEnum.SUCCESS)
        ? TESTING_STATUSES.SUCCESS
        : TESTING_STATUSES.ERROR;

      setTestingStatus(newStatus);
    }
  }, [checkEnded]);

  useEffect(() => {
    if (isEmpty(checks)) return;

    const isQueueFree = Object.entries(checks).every(([checkName, checkObject]) => {
      return independentTests.includes(checkName as CheckNameEnum) || checkObject.status !== CheckStatusEnum.LOADING;
    });

    if (isQueueFree) {
      setIndependentTests(independentTests.slice(1));
    }
  }, [checks]);

  useMemo(() => {
    if (isEmpty(checks) || testingStatus !== TESTING_STATUSES.ERROR) return;

    // We take errors only from this three checks
    const checksToSendErrors = [CheckNameEnum.DATABASE, CheckNameEnum.MEDIA, CheckNameEnum.AUDIO_VIDEO];
    const allErrors = checksToSendErrors.reduce((acc, checkName) => {
      return checks[checkName]?.errors
        ? acc.concat(
            checks[checkName].errors.map((error: string) => ({
              errorText: error,
              errorLink: SUPPORT_LINKS[checkName],
            })),
          )
        : acc;
    }, []);

    if (!allErrors.length) return;

    setErrors(allErrors);
    setHelpBlockVisibility(true);
  }, [testingStatus]);

  useEffect(() => {
    if (!isOnline) {
      setWasOffline(true);
    }
  }, [isOnline]);

  return (
    <SystemCheckContext.Provider value={SYSTEM_CHECK_CONTEXT}>
      <div className={classes.pageContainer}>
        <img
          src="https://assets.livingsecurity.com/image/upload/c_scale,w_210/v1613752012/brand-assets/logos/living_security_Horizontal_Color_Light-06_zqdz3a.png"
          alt="Living Security Logo"
          className={classes.logo}
        />
        <h1 className={classes.title}>{t('requirements-check:title')}</h1>
        <div className={classes.testingStatus}>{regionSelect ? t('region:title') : testingStatus}</div>
        <div className={classes.blocksWrapper}>
          {regionSelect ? (
            <div className={classes.regionWrapper}>
              <div className={classes.regionBlock} onClick={() => setRegion(REGIONS.US)}>
                {t('region:us')}
              </div>
              <div className={classes.regionBlock} onClick={() => setRegion(REGIONS.Europe)}>
                {t('region:eu')}
              </div>
              <div className={classes.regionBlock} onClick={() => setRegion(REGIONS.Asia)}>
                {t('region:apac')}
              </div>
            </div>
          ) : (
            <div className={classes.block}>
              <div className={classes.testingBlock}>
                <BrowserCheck />
                <InternetSpeedCheck isIndependent={independentTests.includes(CheckNameEnum.INTERNET_SPEED)} />
                <FirebaseCheck isIndependent={independentTests.includes(CheckNameEnum.DATABASE)} region={region} />
                <CloudinaryCheck />
                <TwilioCheck />
              </div>
            </div>
          )}
          {isHelpBlockVisible ? (
            <div className={classes.block}>
              <Styled.SupportBlock>
                <Title>{t('common:help-title')}</Title>
                <Text>{t('common:help-subtitle')}</Text>
                <Styled.EmailContainer>
                  <CopyToClipboard node={emailNode} />
                  <Styled.Email ref={onRefChange}>
                    <Styled.EmailHeader>
                      <Text>{t('common:hello')}</Text>
                    </Styled.EmailHeader>
                    <Text>{t('common:email-paragraph-1')}</Text>
                    <Text>{t('common:email-paragraph-2')}</Text>
                    <Styled.EmailList>
                      {errors.map(({ errorText, errorLink }) => (
                        <Styled.EmailListItem key={errorText}>
                          <Text>
                            {`${errorText} - `}
                            <Link text={t('common:support-documentation')} href={errorLink} />
                          </Text>
                        </Styled.EmailListItem>
                      ))}
                    </Styled.EmailList>
                    <Text>
                      <Text>
                        {t('common:email-paragraph-3')} <Link href="https://test.livingsecurity.com" />
                      </Text>
                    </Text>
                    <Text>{t('common:thank-you')}</Text>
                  </Styled.Email>
                </Styled.EmailContainer>
                <Title>{t('common:let-living-security-know')}</Title>
                <Text>{t('common:help-our-team')}</Text>
                <Styled.Form onSubmit={sendResults}>
                  <Input placeholder={t('placeholder:email')} type="email" onChange={inputHandler} value={userEmail} />
                  <Button disabled={isLoading || !isEmail(userEmail)}>{t('common:submit')}</Button>
                </Styled.Form>
              </Styled.SupportBlock>
            </div>
          ) : null}
        </div>
      </div>
      <NoInternetModal open={wasOffline} />
      {testingStatus === TESTING_STATUSES.SUCCESS && <Confetti />}
    </SystemCheckContext.Provider>
  );
}

export default SystemRequirementsCheckPage;
