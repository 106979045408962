import compareVersions from 'compare-versions';
import { BrowserNameEnum, TestResultEnum, TestErrorEnum } from './types';

const availableBrowsers = [BrowserNameEnum.CHROME, BrowserNameEnum.EDGE];
const minBrowserVersion = {
  [BrowserNameEnum.CHROME]: '92.0.4515',
  [BrowserNameEnum.EDGE]: '92.0.902',
};

const getBrowserInfo = (userAgent: string) => {
  const browserInfo = userAgent.match(/(chrome|safari)[/\s]([\d.]+)/i);
  return browserInfo ? { name: browserInfo[1], version: browserInfo[2] } : null;
};

export const runBrowserTest = () => {
  const browserEntity = getBrowserInfo(window.navigator.userAgent);

  if (!browserEntity) {
    return {
      status: TestResultEnum.ERROR,
      name: TestErrorEnum.INCOMPLETE,
      report: browserEntity,
    };
  }
  const allowedBrowser = availableBrowsers.find((browser) => browser === browserEntity.name);

  if (!allowedBrowser) {
    return {
      status: TestResultEnum.ERROR,
      name: TestErrorEnum.UNSUPPORTED,
      report: browserEntity,
    };
  }
  const isMostRecent = compareVersions.compare(browserEntity.version, minBrowserVersion[allowedBrowser], '>=');
  return isMostRecent
    ? { status: TestResultEnum.SUCCESS, name: TestErrorEnum.EMPTY, report: browserEntity }
    : { status: TestResultEnum.ERROR, name: TestErrorEnum.OLD, report: browserEntity };
};
export * from './types';
