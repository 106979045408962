export enum CheckStatusEnum {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  FAIL = 'FAIL',
}

export enum CheckNameEnum {
  BROWSER = 'BROWSER',
  INTERNET_SPEED = 'INTERNET_SPEED',
  DATABASE = 'DATABASE',
  MEDIA = 'MEDIA',
  AUDIO_VIDEO = 'AUDIO_VIDEO',
}

export type Result = {
  title: string;
  warning: [];
  errors: [];
  status: [];
  reports: [];
};

export type ErrorType = {
  errorText: string;
  errorLink: string;
};

export type CheckData = {
  status: keyof typeof CheckStatusEnum;
  errors: string[];
  warnings: string[];
};
