export enum TestResultEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type TestResult = {
  domain: string;
  status: keyof typeof TestResultEnum;
};

export interface ImageItem {
  domain: string;
  url: string;
}
