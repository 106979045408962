import styled from 'styled-components';

import { Text } from '../../components/Text/styles';
import { Title } from '../../components/Title/styles';
import { Button } from '../../components/Button/styles';
import { CopyToClipboard } from '../../components/CopyToClipboard/styles';

export const SupportBlock = styled.div`
  padding: 32px;
  width: 679px;

  & > ${Title} {
    margin-bottom: 32px;
  }

  & > ${Text} {
    margin-bottom: 34px;
  }
`;

export const EmailContainer = styled.div`
  position: relative;
  margin-bottom: 34px;

  ${CopyToClipboard} {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;

export const Email = styled.div`
  padding: 29px 14px 24px 20px;
  background: #f4f7f9;

  ${Text} {
    color: #636f7e;
  }

  & > ${Text} {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const EmailHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;

  ${Text} {
    flex-grow: 1;
    margin-right: 20px;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const EmailList = styled.ul`
  margin-bottom: 24px;
  padding: 0;
`;

export const EmailListItem = styled.li`
  margin: 0 0 18px;

  &::first-letter {
    text-transform: uppercase;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  max-width: 500px;
  ${Button} {
    margin-left: 20px;
  }
`;
