import i18n from 'i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import english from '../locales/en/translation';
import spanish from '../locales/es/translation';
import japanese from '../locales/ja/translation';

i18n
  // Detect user language - Learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ICU)
  // Init i18next - For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: { ...english },
      es: { ...spanish },
      ja: { ...japanese },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    detection: {
      lookupQuerystring: 'lang',
    },
  });

export default i18n;
