import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  overallModalWrapper: {
    position: 'fixed',
    zIndex: 1000,
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(0 0 0 / 32%)',
  },
  modalContainer: {
    position: 'relative',
    borderRadius: 8,
    zIndex: 1000,
    width: 540,
    minHeight: 150,
    maxHeight: 'calc(100vh - 24px)',
    margin: '0 auto',
    backgroundColor: '#ffffff',
  },
  header: {
    width: '100%',
    background: '#4EB3D4',
    color: '#ffffff',
    padding: '.5em 1em',
    fontSize: 24,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxSizing: 'border-box',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontSize: 13,
    fontWeight: 'lighter',
  },
  body: {
    width: '100%',
    position: 'relative',
    padding: '1.5em',
    fontSize: 18,
    fontWeight: 'lighter',
  },
});

type Props = {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
};

export default function (props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.overallModalWrapper}>
      <div className={classes.modalContainer}>
        {!!(props.title || props.subtitle) && (
          <div className={classes.header}>
            <div className={classes.title}>{props.title}</div>
            <div className={classes.subtitle}>{props.subtitle}</div>
          </div>
        )}
        <div className={classes.body}>{props.children}</div>
      </div>
    </div>
  );
}
