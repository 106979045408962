import React, { useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';

import { CheckData, CheckNameEnum, CheckStatusEnum, Result } from './types';

function useSystemCheck() {
  // @ts-ignore
  const [checks, setChecks] = useState<Record<keyof typeof CheckNameEnum, any>>({});
  const [resultData, setResultData] = useState<Result[]>([]);
  const setCheck = useCallback((name: CheckNameEnum, checkData: CheckData) => {
    setChecks((prev) => ({
      ...prev,
      [name]: checkData,
    }));
  }, []);

  const addErrorToCheck = useCallback((name: CheckNameEnum, error) => {
    setChecks((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        errors: prev[name].errors.concat(error),
      },
    }));
  }, []);

  const addWarningToCheck = useCallback((name: CheckNameEnum, warning) => {
    setChecks((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        warnings: prev[name].warnings.concat(warning),
      },
    }));
  }, []);

  const changeCheckStatus = useCallback((name: CheckNameEnum, status: CheckStatusEnum) => {
    setChecks((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        status,
      },
    }));
  }, []);

  const startCheck = useCallback((name: CheckNameEnum) => {
    setChecks((prev) => ({
      ...prev,
      [name]: {
        status: CheckStatusEnum.LOADING,
        errors: [],
        warnings: [],
      },
    }));
  }, []);

  const mergeResult = (res: Result) => {
    setResultData((prevState) => {
      if (prevState.some((result) => result.title === res.title)) return prevState;
      return [...prevState, res];
    });
  };

  const checkEnded = useMemo(() => {
    if (isEmpty(checks)) {
      return false;
    }

    return (Object.values(checks) as CheckData[]).every(({ status }) => status !== CheckStatusEnum.LOADING);
  }, [checks]);

  return {
    checks,
    setCheck,
    checkEnded,
    addErrorToCheck,
    addWarningToCheck,
    changeCheckStatus,
    startCheck,
    resultData,
    mergeResult,
  };
}

export default useSystemCheck;
