import { TestResultEnum, TestResult, ImageItem } from './types';

const SUCCESS_STATUS = 200;

const images = [
  {
    url: 'https://puzzles.cdn.livingsecurity.com/Hotspot/SearchAndFind.jpg',
    domain: 'https://puzzles.cdn.livingsecurity.com/',
  },
  {
    url: 'https://images.ctfassets.net/2r59z1s22s5z/6Pk3wb63PqPhhbODkwL08W/5e1f769faa34d3e4a0327815cb31a2ec/SECURITY_BASICS_Malware_withTEXT.jpg',
    domain: 'https://images.ctfassets.net/',
  },
];

const loadImage = async (resolve: Function, { url, domain }: ImageItem) => {
  let status: TestResultEnum = TestResultEnum.SUCCESS;

  try {
    const response = await fetch(url);

    if (response.status !== SUCCESS_STATUS) {
      throw new Error();
    }
  } catch {
    status = TestResultEnum.ERROR;
  } finally {
    resolve({
      domain,
      status,
    });
  }
};

export const startPuzzlesDiagnostic = async () => {
  return images.map(
    (image) =>
      new Promise<TestResult>((resolve) => {
        loadImage(resolve, image);
      }),
  );
};

export * from './types';
