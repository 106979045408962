import React, { useEffect, useMemo, useState } from 'react';
import CheckBlock from 'containers/TestingPage/components/CheckBlock/CheckBlock';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';
import { CheckNameEnum, CheckStatusEnum } from 'containers/TestingPage/hooks/types';
import { useNetworkSpeedTest, DEFAULT_RESULTS } from 'services/internet-speed-diagnostic';
import { TestStatusEnum } from 'services/internet-speed-diagnostic/types';
import useSystemCheckContext from 'containers/TestingPage/hooks/useSystemCheckContext';
import { SUPPORT_LINKS } from '../../../../constants';

const MINIMUM_SPEED = {
  DOWNLOAD: 3,
  UPLOAD: 1,
};

type Check = {
  isIndependent: boolean;
};

type Wrapper = {
  setState: Function;
};

function ResultsWrapper({ setState }: Wrapper) {
  const results = useNetworkSpeedTest({
    type: 'IMAGE',
    mediaLink: 'https://puzzles.cdn.livingsecurity.com/Hotspot/TrueEyeOffice/TrueEye-Demo1.gif',
    sizeInBytes: 7986678,
    // mediaLink: 'https://puzzles.cdn.livingsecurity.com/Hotspot/VTX/vtx-back.jpg', // smaller image
    // sizeInBytes: 1105052, // smaller image
    uploadUrl: `${process.env.REACT_APP_API_ROOT}/v1/test-upload-speed`,
  });

  useEffect(() => {
    setState(results);
  }, [setState, results]);

  return null;
}

function InternetSpeedCheck({ isIndependent }: Check) {
  const { t } = useTranslation();

  const { checks, startCheck, addWarningToCheck, addErrorToCheck, changeCheckStatus, mergeResult } =
    useSystemCheckContext();

  const [results, setResults] = useState(DEFAULT_RESULTS);

  const check = useMemo(() => {
    return checks[CheckNameEnum.INTERNET_SPEED];
  }, [checks]);

  const loadingPercentage = useMemo(() => {
    return (
      (Object.values(results).filter((result) => result.status !== TestStatusEnum.IN_PROGRESS).length /
        Object.keys(results).length) *
      100
    );
  }, [results]);

  const subtitle = useMemo(() => {
    if (results.download.status === TestStatusEnum.IN_PROGRESS) {
      return t('in-progress:testing', { what: t('in-progress:download-speed') });
    }
    /*
    if (results?.upload.status === TestStatusEnum.IN_PROGRESS) {
      return t('in-progress:testing', { what: t('in-progress:upload-speed') });
    }
     */
    const slowDownload = results.download.speedInMbps < MINIMUM_SPEED.DOWNLOAD;
    // const slowUpload = results.upload.speedInMbps < MINIMUM_SPEED.UPLOAD;
    return slowDownload ? t('tests:internet-speed-failed') : t('tests:internet-speed-successful');
  }, [results]);

  useEffect(() => {
    startCheck(CheckNameEnum.INTERNET_SPEED);
  }, []);

  useEffect(() => {
    if (Object.values(results).every(({ status }) => status !== TestStatusEnum.IN_PROGRESS)) {
      const slowDownload = results.download.speedInMbps < MINIMUM_SPEED.DOWNLOAD;
      // const slowUpload = results.upload.speedInMbps < MINIMUM_SPEED.UPLOAD;

      const downloadTimeout = results.download.status === TestStatusEnum.FAILED;
      // const uploadTimeout = results.upload.status === TestStatusEnum.FAILED;

      if (downloadTimeout) {
        if (downloadTimeout) {
          datadogLogs.logger.error(`TEST FAILED: Download Speed = ${results.download.speedInMbps}`);
          addErrorToCheck(CheckNameEnum.INTERNET_SPEED, t('errors:internet-speed:download-timeout'));
        }
        /*
        if (uploadTimeout) {
          datadogLogs.logger.error(`TEST FAILED: Upload Speed = ${results.upload.speedInMbps}`);
          addErrorToCheck(CheckNameEnum.INTERNET_SPEED, t('errors:internet-speed:upload-timeout'));
        }
         */
      } else {
        if (slowDownload) {
          datadogLogs.logger.info(`TEST WARNING: Download Speed = ${results.download.speedInMbps}`);
          addWarningToCheck(CheckNameEnum.INTERNET_SPEED, t('errors:internet-speed:slow-download'));
        } else {
          datadogLogs.logger.info(`TEST PASSED: Download Speed = ${results.download.speedInMbps}`);
        }
        /*
        if (slowUpload) {
          datadogLogs.logger.info(`TEST WARNING: Upload Speed = ${results.upload.speedInMbps}`);
          addWarningToCheck(CheckNameEnum.INTERNET_SPEED, t('errors:internet-speed:slow-upload'));
        } else {
          datadogLogs.logger.info(`TEST PASSED: Upload Speed = ${results.upload.speedInMbps}`);
        }
         */
      }

      const status =
        downloadTimeout || slowDownload
        // || uploadTimeout || slowUpload
          ? CheckStatusEnum.FAIL : CheckStatusEnum.SUCCESS;
      changeCheckStatus(CheckNameEnum.INTERNET_SPEED, status);
      mergeResult({
        title: t('tests:internet-speed'),
        warning: check.warnings,
        errors: check.errors,
        status,
        reports: results,
      });
    }
  }, [results]);

  return check ? (
    <>
      <CheckBlock
        title={t('tests:internet-speed')}
        subtitle={subtitle}
        warnings={check.warnings}
        errors={check.errors}
        loadingPercentage={loadingPercentage}
        status={check.status}
        troubleshootUrl={SUPPORT_LINKS.INTERNET_SPEED}
      />
      {!isIndependent && <ResultsWrapper setState={setResults} />}
    </>
  ) : null;
}

export default InternetSpeedCheck;
