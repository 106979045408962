import React from 'react';

import * as Styled from './styles';

type InputProps = {
  placeholder: string;
  type: string;
  name?: string;
  value?: string;
  onChange?: ({ target: { value } }: { target: { value: string } }) => void;
  onBlur?: ({ target: { value } }: { target: { value: string } }) => void;
  isDisabled?: boolean;
  onDelete?: () => void;
};
const Input = ({ placeholder, type, name, value, onChange, onBlur, isDisabled, onDelete, ...rest }: InputProps) => {
  return (
    <Styled.Input
      placeholder={placeholder}
      type={type}
      name={name}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      disabled={isDisabled}
      {...rest}
    />
  );
};

export default Input;
