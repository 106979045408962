import { createContext } from 'react';
import { CheckNameEnum, CheckStatusEnum, CheckData } from '../hooks/types';

const DEFAULT_STATE = {
  checks: {} as Record<keyof typeof CheckNameEnum, any>,
  setCheck: (name: CheckNameEnum, checkData: CheckData) => {},
  checkEnded: false,
  addErrorToCheck: (name: CheckNameEnum, error: any) => {},
  addWarningToCheck: (name: CheckNameEnum, warning: any) => {},
  changeCheckStatus: (name: CheckNameEnum, status: CheckStatusEnum) => {},
  startCheck: (name: CheckNameEnum) => {},
  mergeResult: (res: any) => {},
};

export default createContext(DEFAULT_STATE);
