import axios, { AxiosResponse } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { Result } from 'containers/TestingPage/hooks/types';

export const sendTestResults = async (email: string, data: Result[]): Promise<AxiosResponse<{ message: string }>> => {
  datadogLogs.logger.info('Saving Test Results: /v1/test-results/save', {
    payload: {
      email,
      data,
      time: new Date(),
    },
  });
  return await axios.post(`${process.env.REACT_APP_API_ROOT}/v1/test-results/save`, {
    email,
    result: { data, time: new Date() },
  });
};
