import React from 'react';

import * as Styled from './styles';

type ButtonProps = {
  children: string;
  disabled?: boolean;
  onClick?: Function;
};
const Button = ({ children, disabled, ...rest }: ButtonProps) => {
  return (
    <Styled.Button {...rest} disabled={disabled}>
      {children}
    </Styled.Button>
  );
};

export default Button;
