import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';

import { startCloudinaryDiagnostic, TestResultEnum as CloudinaryTestResultEnum } from 'services/cloudinary-diagnostic';
import { startPuzzlesDiagnostic, TestResultEnum as PuzzlesTestResultEnum } from 'services/puzzles-diagnostic';

import { CheckNameEnum, CheckStatusEnum } from '../../hooks/types';
import useSystemCheckContext from '../../hooks/useSystemCheckContext';

import { SUPPORT_LINKS } from '../../../../constants';

import CheckBlock from '../CheckBlock/CheckBlock';

const RESOLUTION_MESSAGE = (resolution: string) => `Video will not work at ${resolution} resolution!`;
const DOMAIN_MESSAGE = (domain: string) => `Page can't load image from ${domain} domain!`;

function CloudinaryCheck() {
  const { t } = useTranslation();
  const { checks, startCheck, addErrorToCheck, changeCheckStatus, mergeResult } = useSystemCheckContext();
  const [testsStatuses, setTestsStatuses] = useState({
    total: 0,
    fulfilled: 0,
  });
  const [subtitle, setSubtitle] = useState<string>(t('in-progress:testing', { what: 'Cloudinary' }));

  const loadingPercentage = useMemo(() => {
    return (testsStatuses.fulfilled / testsStatuses.total) * 100;
  }, [testsStatuses]);

  const check = useMemo(() => {
    return checks[CheckNameEnum.MEDIA];
  }, [checks]);

  const MAIN_MANIFEST_ERROR = t('errors:media:main-manifest');
  const IMAGES_ERROR = t('errors:media:images');

  useEffect(() => {
    (async () => {
      try {
        !checks[CheckNameEnum.MEDIA] && startCheck(CheckNameEnum.MEDIA);

        const cloudinaryTests = await startCloudinaryDiagnostic();

        setTestsStatuses((prev) => ({
          ...prev,
          total: prev.total + cloudinaryTests.length,
        }));

        cloudinaryTests.forEach((test) => {
          test.then((testResult) => {
            if (testResult.status === CloudinaryTestResultEnum.ERROR) {
              addErrorToCheck(CheckNameEnum.MEDIA, RESOLUTION_MESSAGE(testResult.resolution));
            }

            setTestsStatuses((prev) => ({
              ...prev,
              fulfilled: prev.fulfilled + 1,
            }));
          });
        });
      } catch (e) {
        addErrorToCheck(CheckNameEnum.MEDIA, MAIN_MANIFEST_ERROR);
        changeCheckStatus(CheckNameEnum.MEDIA, CheckStatusEnum.FAIL);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        !checks[CheckNameEnum.MEDIA] && startCheck(CheckNameEnum.MEDIA);

        const puzzlesTests = await startPuzzlesDiagnostic();

        setTestsStatuses((prev) => ({
          ...prev,
          total: prev.total + puzzlesTests.length,
        }));

        puzzlesTests.forEach((test) => {
          test.then((testResult) => {
            if (testResult.status === PuzzlesTestResultEnum.ERROR) {
              addErrorToCheck(CheckNameEnum.MEDIA, DOMAIN_MESSAGE(testResult.domain));
            }

            setTestsStatuses((prev) => ({
              ...prev,
              fulfilled: prev.fulfilled + 1,
            }));
          });
        });
      } catch (e) {
        addErrorToCheck(CheckNameEnum.MEDIA, IMAGES_ERROR);
        changeCheckStatus(CheckNameEnum.MEDIA, CheckStatusEnum.FAIL);
      }
    })();
  }, []);

  useEffect(() => {
    if (testsStatuses.total !== 0 && testsStatuses.total === testsStatuses.fulfilled) {
      const status = check?.errors.length
        ? CheckStatusEnum.FAIL
        : check?.warnings.length
        ? CheckStatusEnum.WARNING
        : CheckStatusEnum.SUCCESS;

      if (status === CheckStatusEnum.FAIL) {
        datadogLogs.logger.error('TEST FAILED: Cloudinary');
      } else {
        datadogLogs.logger.info('TEST PASSED: Cloudinary');
      }

      setSubtitle(status === CheckStatusEnum.SUCCESS ? t('common:success-passed') : t('common:error-passed'));
      changeCheckStatus(CheckNameEnum.MEDIA, status);
      mergeResult({
        title: t('tests:media'),
        warning: check.warnings,
        errors: check.errors,
        status,
      });
    }
  }, [testsStatuses, check?.errors, check?.warnings]);

  return check ? (
    <CheckBlock
      title={t('tests:media')}
      subtitle={subtitle}
      warnings={check.warnings}
      errors={check.errors}
      loadingPercentage={loadingPercentage}
      status={check.status}
      troubleshootUrl={SUPPORT_LINKS.MEDIA}
    />
  ) : null;
}

export default CloudinaryCheck;
