import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';

import { startTwilioDiagnostic, TestNameEnum, TestResultEnum } from 'services/twilio-diagnostic';

import { CheckNameEnum, CheckStatusEnum } from '../../hooks/types';
import useSystemCheckContext from '../../hooks/useSystemCheckContext';

import { SUPPORT_LINKS } from '../../../../constants';

import CheckBlock from '../CheckBlock/CheckBlock';

export default function TwilioCheck() {
  const { t } = useTranslation();
  const [reports, setReports] = useState<any[]>([]);
  const { checks, startCheck, addErrorToCheck, addWarningToCheck, changeCheckStatus, mergeResult } =
    useSystemCheckContext();

  const [testsStatuses, setTestsStatuses] = useState({
    total: 0,
    fulfilled: 0,
  });
  const [subtitle, setSubtitle] = useState<string>(t('in-progress:testing', { what: t('tests:twilio-subtitle') }));

  const loadingPercentage = useMemo(() => {
    return (testsStatuses.fulfilled / testsStatuses.total) * 100;
  }, [testsStatuses]);

  const check = useMemo(() => {
    return checks[CheckNameEnum.AUDIO_VIDEO];
  }, [checks]);

  const isErrorCritical = useCallback((error: TestNameEnum): boolean => {
    const severityOfError = {
      [TestNameEnum.TWILIO_TOKEN]: true,
      [TestNameEnum.UDP_CONNECTIVITY]: true,
      [TestNameEnum.TCP_CONNECTIVITY]: false,
      [TestNameEnum.TLS_CONNECTIVITY]: false,
      [TestNameEnum.VOICE_BANDWIDTH]: false,
      [TestNameEnum.VOICE_PCMU]: false,
      [TestNameEnum.VOICE_OPUS]: false,
      [TestNameEnum.VIDEO_CONNECTIVITY]: true,
      [TestNameEnum.VIDEO_TURN]: true,
      [TestNameEnum.AUDIO_TURN]: true,
    };
    return severityOfError[error];
  }, []);

  const getErrorText = (error: TestNameEnum): string => {
    const severityOfError = {
      [TestNameEnum.TWILIO_TOKEN]: t('errors:video-audio:twilio-token'),
      [TestNameEnum.UDP_CONNECTIVITY]: t('errors:video-audio:udp-connectivity'),
      [TestNameEnum.TCP_CONNECTIVITY]: t('errors:video-audio:tcp-connectivity'),
      [TestNameEnum.TLS_CONNECTIVITY]: t('errors:video-audio:tls-connectivity'),
      [TestNameEnum.VOICE_BANDWIDTH]: t('errors:video-audio:voice-bandwidth'),
      [TestNameEnum.VOICE_PCMU]: t('errors:video-audio:voice-pcmu'),
      [TestNameEnum.VOICE_OPUS]: t('errors:video-audio:video-opus'),
      [TestNameEnum.VIDEO_CONNECTIVITY]: t('errors:video-audio:video-connectivity'),
      [TestNameEnum.VIDEO_TURN]: t('errors:video-audio:video-turn'),
      [TestNameEnum.AUDIO_TURN]: t('errors:video-audio:audio-connectivity'),
    };
    return severityOfError[error];
  };

  useEffect(() => {
    (async () => {
      const tests = await startTwilioDiagnostic();
      startCheck(CheckNameEnum.AUDIO_VIDEO);

      setTestsStatuses((prev) => ({
        ...prev,
        total: tests.length,
      }));

      tests.forEach((test) => {
        test.then((testResult) => {
          setReports((prevState) => [...prevState, testResult.reports]);
          if (testResult.status === TestResultEnum.ERROR) {
            const addErrorOrWarning = isErrorCritical(testResult.name) ? addErrorToCheck : addWarningToCheck;
            addErrorOrWarning(CheckNameEnum.AUDIO_VIDEO, getErrorText(testResult.name));
          } else {
            datadogLogs.logger.info(`TEST PASSED: Twilio - ${testResult.name}`);
          }
          setTestsStatuses((prev) => ({
            ...prev,
            fulfilled: prev.fulfilled + 1,
          }));
        });
      });
    })();
  }, []);

  useEffect(() => {
    if (testsStatuses.total !== 0 && testsStatuses.total === testsStatuses.fulfilled) {
      const status = check?.errors.length
        ? CheckStatusEnum.FAIL
        : check?.warnings.length
        ? CheckStatusEnum.WARNING
        : CheckStatusEnum.SUCCESS;

      setSubtitle(
        status === CheckStatusEnum.SUCCESS
          ? t('common:success-passed')
          : status === CheckStatusEnum.WARNING
          ? t('common:permissions-passed')
          : t('common:error-passed'),
      );
      changeCheckStatus(CheckNameEnum.AUDIO_VIDEO, status);
      mergeResult({
        title: t('tests:video-audio'),
        warning: check.warnings,
        errors: check.errors,
        status,
        reports,
      });
    }
  }, [testsStatuses, check?.errors, check?.warnings]);

  return check ? (
    <CheckBlock
      title={t('tests:video-audio')}
      subtitle={subtitle}
      warnings={check.warnings}
      errors={check.errors}
      loadingPercentage={loadingPercentage}
      status={check.status}
      troubleshootUrl={SUPPORT_LINKS.AUDIO_VIDEO}
    />
  ) : null;
}
