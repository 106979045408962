import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import { CheckStatusEnum } from '../../hooks/types';

import Error from 'assets/images/error.svg';
import Warning from 'assets/images/warning.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    borderBottom: '1px solid #E5E5E5',
  },
  contentWrapper: {
    padding: '30px 30px 12px 35px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 22,
  },
  title: {
    flexGrow: 1,
    marginRight: 5,
    width: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000000',
  },
  failed: {
    padding: '2px 5px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    background: '#F16153',
    textTransform: 'uppercase',
    color: '#fff',
  },
  warn: {
    padding: '2px 5px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    background: '#FFA800',
    textTransform: 'uppercase',
    color: '#fff',
  },
  passed: {
    padding: '2px 5px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    background: '#307267',
    textTransform: 'uppercase',
    color: '#fff',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#000000',
    marginBottom: 18,
  },
  warningColor: {
    color: '#FFA800',
  },
  errorColor: {
    color: '#F16153',
  },
  progressColor: {
    color: '#4EB3D4',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
  },
  errorIcon: {
    marginRight: 4,
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
  },
  warningIcon: {
    marginRight: 4,
  },
  link: {
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 500,
    color: '#2F80ED',
    margin: '20px 0',
  },
  progressBar: ({ loadingPercentage = 0 }: { loadingPercentage?: number }) => ({
    height: 3,
    backgroundColor: '#4EB3D4',
    borderRadius: '0px 5px 5px 0',
    position: 'absolute',
    bottom: -2,
    width: `${loadingPercentage}%`,
    transition: 'width 0.15s ease',
  }),
}));

type CheckBlockProps = {
  title: string;
  subtitle: string;
  warnings: string[];
  errors: string[];
  troubleshootUrl?: string;
  troubleshootText?: string;
  loadingPercentage: number;
  status: keyof typeof CheckStatusEnum;
};

function CheckBlock({
  title,
  subtitle,
  warnings,
  errors,
  troubleshootUrl = 'https://www.livingsecurity.com/support/compatability-testing-for-teams-cyberescape-online',
  troubleshootText,
  status,
  loadingPercentage,
}: CheckBlockProps) {
  const { t } = useTranslation();
  const classes = useStyles({
    loadingPercentage,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status === CheckStatusEnum.LOADING) {
      setLoading(true);
    } else if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [status, loading]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          <div className={classes.title}>
            {status === CheckStatusEnum.LOADING ? `${t('common:checking')} ` : ''}
            {title}
          </div>
          {status === CheckStatusEnum.LOADING && <CircularProgress size={18} className={classes.progressColor} />}
          {status === CheckStatusEnum.SUCCESS && <div className={classes.passed}>{t('common:passed')}</div>}
          {status === CheckStatusEnum.WARNING && <div className={classes.warn}>{t('common:warning')}</div>}
          {status === CheckStatusEnum.FAIL && <div className={classes.failed}>{t('common:failed')}</div>}
        </div>
        <div className={classes.subTitle}>{subtitle}</div>
        <div>
          {errors.map((item, idx) => (
            <div key={idx} className={clsx(classes.error, classes.errorColor)}>
              <img src={Error} alt="result icon" className={classes.errorIcon} />
              {item}
            </div>
          ))}
        </div>
        <div>
          {warnings.map((item, idx) => (
            <div key={idx} className={clsx(classes.warning, classes.warningColor)}>
              <img src={Warning} alt="result icon" className={classes.warningIcon} />
              {item}
            </div>
          ))}
        </div>
        {(status === CheckStatusEnum.FAIL || status === CheckStatusEnum.WARNING) && (
          <a href={troubleshootUrl} className={classes.link} target="_blank" rel="noopener noreferrer nofollow">
            {troubleshootText || t('common:more-info')}
          </a>
        )}
      </div>
      {loading && <div className={classes.progressBar} />}
    </div>
  );
}

export default CheckBlock;
