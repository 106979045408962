import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { runBrowserTest, TestResultEnum, TestErrorEnum } from 'services/browser-diagnostic';

import { CheckNameEnum, CheckStatusEnum } from '../../hooks/types';
import useSystemCheckContext from '../../hooks/useSystemCheckContext';

import { SUPPORT_LINKS } from '../../../../constants';

import CheckBlock from '../CheckBlock/CheckBlock';

function BrowserCheck() {
  const { t } = useTranslation();
  const { checks, startCheck, addErrorToCheck, changeCheckStatus, mergeResult } = useSystemCheckContext();

  const [testsStatuses, setTestsStatuses] = useState({
    total: 0,
    fulfilled: 0,
  });
  const [reports, setReports] = useState<any>([]);

  const loadingPercentage = useMemo(() => {
    return (testsStatuses.fulfilled / testsStatuses.total) * 100;
  }, [testsStatuses]);

  const check = useMemo(() => {
    return checks[CheckNameEnum.BROWSER];
  }, [checks]);

  const getErrorText = (error: TestErrorEnum): string => {
    const severityOfError = {
      [TestErrorEnum.UNSUPPORTED]: t('errors:browser:unsupported'),
      [TestErrorEnum.INCOMPLETE]: t('errors:browser:incomplete'),
      [TestErrorEnum.OLD]: t('errors:browser:old'),
      [TestErrorEnum.EMPTY]: '',
    };
    return severityOfError[error];
  };

  useEffect(() => {
    const test = runBrowserTest();
    startCheck(CheckNameEnum.BROWSER);

    setTestsStatuses((prev) => ({
      ...prev,
      total: 1,
    }));

    if (test.status === TestResultEnum.ERROR) {
      addErrorToCheck(CheckNameEnum.BROWSER, getErrorText(test.name));
    }
    setTestsStatuses((prev) => ({
      ...prev,
      fulfilled: prev.fulfilled + 1,
    }));
    setReports([test.report]);
  }, []);

  useEffect(() => {
    if (testsStatuses.total !== 0 && testsStatuses.total === testsStatuses.fulfilled) {
      const status = check?.errors.length
        ? CheckStatusEnum.FAIL
        : check?.warnings.length
        ? CheckStatusEnum.WARNING
        : CheckStatusEnum.SUCCESS;
      changeCheckStatus(CheckNameEnum.BROWSER, status);
      mergeResult({
        title: t('tests:browser'),
        warning: check.warnings,
        errors: check.errors,
        status,
        reports,
      });
    }
  }, [testsStatuses, check?.errors, check?.warnings]);

  return check ? (
    <CheckBlock
      title={t('tests:browser')}
      subtitle=""
      warnings={check.warnings}
      errors={check.errors}
      loadingPercentage={loadingPercentage}
      status={check.status}
      troubleshootUrl={SUPPORT_LINKS.BROWSER}
    />
  ) : null;
}

export default BrowserCheck;
