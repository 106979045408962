import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';

import { startFirebaseDiagnostic, TestNameEnum, TestResultEnum } from '@livingsecurity/firebase-test';

import { CheckNameEnum, CheckStatusEnum } from '../../hooks/types';
import useSystemCheckContext from '../../hooks/useSystemCheckContext';

import { SUPPORT_LINKS } from '../../../../constants';

import CheckBlock from '../CheckBlock/CheckBlock';
import getFirebaseConfigByRegion, { Region, REGIONS } from '../../../../utils/firebase';

type Check = {
  isIndependent: boolean;
  region?: Region;
};

function FirebaseCheck({ isIndependent, region }: Check) {
  const { t } = useTranslation();
  const { checks, startCheck, addErrorToCheck, addWarningToCheck, changeCheckStatus, mergeResult } =
    useSystemCheckContext();

  const [reports, setReports] = useState<any[]>([]);
  const [testsStatuses, setTestsStatuses] = useState({
    total: 0,
    fulfilled: 0,
  });
  const [subtitle, setSubtitle] = useState<string>(t('in-progress:testing', { what: t('tests:database-subtitle') }));

  const loadingPercentage = useMemo(() => {
    return (testsStatuses.fulfilled / testsStatuses.total) * 100;
  }, [testsStatuses]);

  const check = useMemo(() => {
    return checks[CheckNameEnum.DATABASE];
  }, [checks]);

  const isErrorCritical = useCallback((error: TestNameEnum): boolean => {
    const severityOfError = {
      [TestNameEnum.DEFAULT_OPTIONS]: true,
      [TestNameEnum.AUTO_DETECT_LONG_POLLING]: false,
      [TestNameEnum.DEFAULT_TRANSACTIONS]: false,
      [TestNameEnum.FORCE_LONG_POLLING]: false,
      [TestNameEnum.REALTIME_DEFAULT_OPTIONS]: false,
      [TestNameEnum.REALTIME_AUTO_DETECT_LONG_POLLING]: false,
      [TestNameEnum.REALTIME_FORCE_LONG_POLLING]: false,
    };
    return severityOfError[error];
  }, []);

  const getErrorText = (error: TestNameEnum): string => {
    const severityOfError = {
      [TestNameEnum.DEFAULT_OPTIONS]: t('errors:database:default-options'),
      [TestNameEnum.AUTO_DETECT_LONG_POLLING]: t('errors:database:autodetect-long-polling'),
      [TestNameEnum.DEFAULT_TRANSACTIONS]: t('errors:database:default-transactions'),
      [TestNameEnum.FORCE_LONG_POLLING]: t('errors:database:force-long-polling'),
      [TestNameEnum.REALTIME_DEFAULT_OPTIONS]: t('errors:database:realtime-default-options'),
      [TestNameEnum.REALTIME_AUTO_DETECT_LONG_POLLING]: t('errors:database:realtime-autodetect-long-polling'),
      [TestNameEnum.REALTIME_FORCE_LONG_POLLING]: t('errors:database:realtime-force-long-polling'),
    };
    return severityOfError[error];
  };

  useEffect(() => {
    (async () => {
      const multiRegion = process.env.REACT_APP_MULTI_REGION === 'true';
      const firebaseConfig = getFirebaseConfigByRegion(region);

      if (isUndefined(check) || check.status !== CheckStatusEnum.LOADING) {
        startCheck(CheckNameEnum.DATABASE);
      }

      if (isIndependent) return;

      datadogLogs.logger.debug(`Running Firebase test for the ${region} region`);

      const tests = startFirebaseDiagnostic(
        firebaseConfig?.apiKey ? firebaseConfig : undefined,
        process.env.REACT_APP_FIREBASE_LS_KEY,
        multiRegion ? 20000 : undefined,
      );

      setTestsStatuses((prev) => ({
        ...prev,
        total: tests.length,
      }));

      tests.forEach((test) => {
        test.then((testResult) => {
          setReports((prevState) => [...prevState, testResult.reports]);
          if (testResult.status === TestResultEnum.ERROR) {
            datadogLogs.logger.error(`TEST FAILED: Firebase - ${testResult.name}`);
            addErrorToCheck(CheckNameEnum.DATABASE, getErrorText(testResult.name));
          } else if (testResult.status === TestResultEnum.WARN) {
            datadogLogs.logger.info(`TEST WARNING: Firebase - ${testResult.name}`);
            addWarningToCheck(CheckNameEnum.DATABASE, getErrorText(testResult.name));
          } else {
            datadogLogs.logger.info(`TEST PASSED: Firebase - ${testResult.name}`);
          }
          setTestsStatuses((prev) => ({
            ...prev,
            fulfilled: prev.fulfilled + 1,
          }));
        });
      });
    })();
  }, [isIndependent]);

  useEffect(() => {
    if (testsStatuses.total !== 0 && testsStatuses.total === testsStatuses.fulfilled) {
      const status = check?.errors.length
        ? CheckStatusEnum.FAIL
        : check?.warnings.length
        ? CheckStatusEnum.WARNING
        : CheckStatusEnum.SUCCESS;

      setSubtitle(status === CheckStatusEnum.SUCCESS ? t('common:success-passed') : t('common:error-passed'));
      changeCheckStatus(CheckNameEnum.DATABASE, status);
      mergeResult({
        title: t('tests:database'),
        warning: check.warnings,
        errors: check.errors,
        status,
        reports,
      });
    }
  }, [testsStatuses, check?.errors, check?.warnings]);

  return check ? (
    <CheckBlock
      title={t('tests:database')}
      subtitle={subtitle}
      warnings={check.warnings}
      errors={check.errors}
      loadingPercentage={loadingPercentage}
      status={check.status}
      troubleshootUrl={SUPPORT_LINKS.DATABASE}
    />
  ) : null;
}

export default FirebaseCheck;
