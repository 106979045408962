import styled, { css } from 'styled-components';

export const Button = styled.button`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 145px;
  padding: 15px 45px;
  border: none;
  border-radius: 5px;
  background-color: #4eb3d4;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  overflow: hidden;
  white-space: nowrap;

  @media only screen and (max-width: 575px) {
    min-width: 160px;
    padding: 0 16px;
  }

  @media only screen and (max-width: 460px) {
    padding: 0 10px;
  }

  &:hover {
    background-color: rgba(78, 179, 212, 0.5);
    color: rgba(255, 255, 255, 0.72);
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      background-color: rgba(78, 179, 212, 0.5);
      cursor: not-allowed;
      pointer-events: none;
    `}
  ${({ inverted }) =>
    inverted &&
    css`
      background: transparent;
      border: 1px solid #4eb3d4;
      color: #4eb3d4;
    `}
  ${({ cancel }) =>
    cancel &&
    css`
      background: none;
      color: #373636;
      &:hover {
        color: #373636;
        background: #d8d8d8;
      }
    `}
  ${({ transparent }) =>
    transparent &&
    css`
      background: none;
      color: #626e76;
      border: 1px solid #edf0f2;
      &:hover {
        color: #373636;
        background: #d8d8d8;
      }
    `}
`;
