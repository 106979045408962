import React from 'react';

import * as Styled from './styles';

type LinkProps = {
  text?: string;
  href: string;
};

export default ({ text = '', href }: LinkProps) => (
  <Styled.Link target="_blank" rel="noopener noreferrer nofollow" href={href}>
    {text || href}
  </Styled.Link>
);
