export enum TestResultEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type TestResult = {
  name: TestNameEnum;
  status: keyof typeof TestResultEnum;
  reports: any[];
};

export enum TestNameEnum {
  TWILIO_TOKEN = 'TWILIO_TOKEN',
  UDP_CONNECTIVITY = 'UDP_CONNECTIVITY',
  TCP_CONNECTIVITY = 'TCP_CONNECTIVITY',
  TLS_CONNECTIVITY = 'TLS_CONNECTIVITY',
  VOICE_BANDWIDTH = 'VOICE_BANDWIDTH',
  VOICE_PCMU = 'VOICE_PCMU',
  VOICE_OPUS = 'VOICE_OPUS',
  VIDEO_CONNECTIVITY = 'VIDEO_CONNECTIVITY',
  VIDEO_TURN = 'VIDEO_TURN',
  AUDIO_TURN = 'AUDIO_TURN',
}
